<template>
    <div id="login-div">
        <div class="title-holder">
            <span>
                Fluke
            </span>
            Portal do distribuidor
        </div>
        <div class="login-card">
            Defina uma nova.<br />
            <br />
            <v-form v-model="isValid" ref="form">
                <v-text-field
                    outlined
                    label="Nova senha"
                    type="password"
                    :rules="[ requiredRule ]"
                    v-model="auth.password" />
                <v-text-field
                    type="password"
                    outlined
                    :rules="[ requiredRule, mustMatch ]"
                    label="Repita a nova senha"
                    v-model="auth.rePass" />
            </v-form>
        </div>
        <div class="btn-aligner">
            <div class="btns-aux"></div>
            <div class="btns-main">
                <v-btn
                    large
                    depressed
                    block
                    color="primary"
                    @click="reset">

                    DEFINIR
                </v-btn>
            </div>
        </div>
        <!-- <v-progress-linear indeterminate></v-progress-linear> -->
    </div>
</template>

<script>
import validatorRules from '../../helpers/validator_rules'


export default {
    data: () => ({
        auth: {
            password: '',
            rePass: '',
            token: ''
        },
        isValid: false
    }),
    computed: {
        requiredRule() {
            return validatorRules.required('Campo obrigatório')
        },
        mustMatch() {
            return validatorRules.confirmPassword( this.auth.password ,'Esse campo está escrito diferente da Nova senha')
        }
    },
    methods: {
        async reset() {
            this.$refs.form.validate()
            if( !this.isValid ) return false
            
            this.auth.token = this.$route.params.token
            const response = await this.$store.dispatch( 'auth/setPasswordToken', this.auth )
            this.$router.push({
                name: 'login'
            })
            // if( response == false ){
            //     alert('fail')
            // } else {
            //     this.$router.push({
            //         name: 'user-list'
            //     })
            // }
        }
    }
}
</script>
